import * as s from './styles/product.module.scss';
import Badge from './badge';
import Features from './features';
import React, { ReactElement, ReactNode } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';

interface Props {
  reverse?: boolean
  imgName: string
  to?: string
  list?: string[]
  alt: string
  title: string
  description: string
  className?: string
  children?: ReactNode
}

const query = graphql`
  {
    allFile(filter: {relativeDirectory: {eq: "products"}}, sort: {fields: name}) {
      nodes {
        childImageSharp {
          gatsbyImageData
        }
        name
      }
    }
  }
`

export default function Product({
  reverse,
  imgName,
  to,
  list,
  alt,
  title,
  description,
  className,
  children,
}: Props): ReactElement {
  const data = useStaticQuery(query)
  const images = data.allFile.nodes.filter((item: any) => item.name.startsWith(imgName))

  return (
    <section className={`${reverse ? s.Product___reverse : ''} ${className}`}>
      <figure className={`${s.Product_figure}`}>
        <GatsbyImage objectFit="contain" image={images[1].childImageSharp.gatsbyImageData} alt={alt} className={s.Product_image} />
        <GatsbyImage objectFit="contain" image={images[0].childImageSharp.gatsbyImageData} alt={alt} className={s.Product_image___forward} />
        <figcaption>
          <h1 className={s.Product_title}>{title}</h1>
          <h2 className="subheading">{description}</h2>
          {children && (
            <div className={s.Product_children}>
              {children}
            </div>
          )}
        </figcaption>
      </figure>
      {list && (<Features to={to} list={list} primary={reverse} name={imgName} />)}
    </section>
  )
}
