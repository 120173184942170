import * as s from './styles/modules.module.scss';
import React, { ReactElement } from 'react';

interface Props {
  subtitle: string
  list: {
    title: string
    icon: string
    topics: string[]
  }[]
}

export default function Modules({ subtitle, list }: Props): ReactElement {
  return (
    <section className={`${s.Modules} u-background-gray`}>
      <div className={s.Modules_box}>
        <h1 className="title">Módulos</h1>
        <h2 className="subtitle">{subtitle}</h2>
        <div className={s.Modules_grid}>
          {list.map((value, i) => (
            <div key={`${value.title}${i}`} className={s.Modules_module}>
              <span className={`material-icons ${s.Modules_icon}`}>{value.icon}</span>
              <h1 className="title">{value.title}</h1>
              <ul className="list">
                {value.topics.map((topic, i) => (
                  <li key={`${value.title}topic${i}`}>
                    {topic}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
