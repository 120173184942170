import * as s from '../../styles/produto.module.scss';
import Button from '../../../components/button';
import CTA from '../../../components/cta';
import Layout from '../../../components/layout';
import Modules from '../../../components/modules';
import Product from '../../../components/product';
import React, { ReactElement } from 'react';

export default function MR1(): ReactElement {
  return (
    <Layout title="MR1">
      <main>
        <Product
          title="MR1"
          description="O Sistema de Gestão Empresarial da SysRS."
          alt="Imagem MR1"
          imgName="mr1"
          className={`${s.Section} u-nav-clear-padding u-background-gray`}
        >
          <Button to="/produtos/mr1/novidades" outline>Novidades</Button>
        </Product>

        <section className={s.About}>
          <div className={s.About_box}>
            <h1 className="title">Sobre o MR1</h1>
            <h2 className="subtitle">Veja os seus recursos e tecnologias</h2>
            <p>
              O MR1 é um <b>poderoso ERP</b> modulável que se adapta a seu segmento mercantil. Baseado na arquitetura
              cliente/servidor, foi projetado trabalhar com banco de dados relacional, garantindo a <b>segurança e
                integridade</b> de seus dados. Devido a sua modularização, você poderá contratar apenas as partes do sistema
              que irá utilizar, gerando um <b>custo menor para sua empresa</b>.
            </p>
          </div>
        </section>

        <Modules
          subtitle="Conheça os módulos do MR1"
          list={[
            {
              icon: 'payments',
              title: 'Contas a Receber',
              topics: [
                'Controle de duplicatas, cheques e outros documentos a receber.',
                'Controle de saldo dos clientes.',
                'Controle de período de lançamentos.',
                'Controle de comissões dos representantes.',
                'Curva ABC de clientes.',
                'Permite estornos de liquidações.',
                'Relatório retroativo de documentos.',
                'Relatório retroativo de pendência por clientes.',
                'Relatórios contábeis: Diário, Razão e Balancete.',
                'Relatório de liquidações e outros.',
              ]
            },

            {
              icon: 'precision_manufacturing',
              title: 'Produção',
              topics: [
                'Programação a nível de item de pedido.',
                'Geração de talões e lotes de produção.',
                'Controle de produção por setor.',
                'Impressão de talões e rótulos, com código de barras.',
                'Baixa manual ou por código de barras, dos talões.',
                'Relatórios de controle da produção.',
                'Relatórios de acompanhamento da produção.',
                'Controle de EAN-13 para produtos pronto.',
                'Segmentos como metalúrgicas, calçadista e componentes.',
              ]
            },

            {
              icon: 'receipt_long',
              title: 'Notas de Saída',
              topics: [
                'NF-e / NFC-e.',
                'Controle de numeração da notas fiscais por filial e série.',
                'Pemite nota de saída para clientes e/ou fornecedores.',
                'Faturamento gerado automaticamente através do pedido.',
                'Faturamento por pedido, a nível da grade de cada item.',
                'Controle de limite de crédito por cliente ou grupo econômico.',
                'Atualiza o saldo de materiais em poder de terceiros.',
                'EDI de notas fiscais com os clientes (configurável).',
                'Emissão de notas fiscais, duplicatas e boletos.',
                'Relatórios diversos de notas fiscais de saída.',
              ]
            },

            {
              icon: 'bar_chart',
              title: 'Vendas',
              topics: [
                'Recebimento de pedidos via EDI (configurável).',
                'Digitação de pedidos com grade (configurável).',
                'Tabela de preços dos produtos.',
                'Controle de limite de crédito por cliente ou grupo econômico.',
                'Controle de comissão de representantes.',
                'Controle do saldo a faturar do pedido.',
                'Relatórios de vendas sobre pedidos.',
                'Relatórios de vendas sobre produtos.',
              ]
            },

            {
              icon: 'request_quote',
              title: 'Contas a Pagar',
              topics: [
                'Controle de documentos a pagar.',
                'Controle de adiantamentos a fornecedores.',
                'Controle de saldo dos fornecedores.',
                'Controle de período de lançamentos.',
                'Lançamento automático de custos fixos da empresa.',
                'Relatório retroativo de documentos.',
                'Relatório de liquidações e outros.',
                'Relatórios contábeis: Diário, Razão e Balancete.',
              ]
            },

            {
              icon: 'inventory_2',
              title: 'Estoque',
              topics: [
                'Trabalha com múltiplas filiais, locais e tipos de estoque.',
                'Trabalha com o conceito de grade de materiais.',
                'Controle do saldo de produto pronto e matéria-prima.',
                'Processo completo de inventário do estoque, com 3 contagens.',
                'Controle de período de lançamentos.',
                'Controle de saldos externos/internos por nota fiscal.',
                'Relatório de movimentação, extrato de materiais e outros.',
                'Relatório retroativo de inventário do estoque.',
              ]
            },

            {
              icon: 'price_change',
              title: 'Fluxo de Caixa',
              topics: [
                'Lançamentos de receitas e despesas futuras diretamente no Fluxo de Caixa.',
                'Projeta os valores de pedidos e ordens de compra em aberto.',
                'Relatórios retroativos de movimentos que compõe o fluxo.',
                'Relatório analítico e sintéticos do Fluxo de Caixa.',
              ]
            },

            {
              icon: 'shopping_cart',
              title: 'Compras',
              topics: [
                'Controle de liberação da ordem de compra.',
                'Tabela de preços de materiais por fornecedor.',
                'Trabalha com grade de materiais.',
                'Envia a ordem de compra por e-mail.',
                'Relatório retroativo de compras realizadas e saldo a receber.',
                'Relatório de divergência entre a nota de entrada e a ordem de compra.',
              ]
            },

            {
              icon: 'description',
              title: 'Notas de Entrada',
              topics: [
                'Entrada através do XML ou da Ordem de Compra.',
                'Aceita fornecedor e/ou cliente como emitente.',
                'Valida se existe ordem de compra (configurável).',
                'Download do XML para NF-e.',
                'Manifestação do destinatário.',
                'Relatórios diversos de documentos de entrada.',
              ]
            },

            {
              icon: 'square_foot',
              title: 'Engenharia',
              topics: [
                'Ficha técnica do produto.',
                'Desenvolvemos conforme a necessidade do cliente.',
              ]
            },

            {
              icon: 'lock',
              title: 'Controle de acesso',
              topics: [
                'Definições por perfil e/ou usuário.',
                'Controle de permissão por empresa e/ou filial.',
                'Permissões a nível de ações como inclusão, exclusão, alteração, consulta e impressão.',
              ]
            },

            {
              icon: 'savings',
              title: 'Caixa e Bancos',
              topics: [
                'Controle total do caixa da empresa.',
                'Controle de conta corrente dos bancos.',
                'Relatórios de extrato, diário e balancete.',
              ]
            },

            {
              icon: 'phone_android',
              title: 'Mobile',
              topics: [
                'XXX.',
              ]
            },

            {
              icon: 'content_cut',
              title: 'Atelieres',
              topics: [
                'XXX.',
              ]
            },

            {
              icon: 'local_shipping',
              title: 'MDF-e',
              topics: [
                'XXX.',
              ]
            },

            {
              icon: 'event',
              title: 'Automação de cobranças',
              topics: [
                'XXX.',
              ]
            },

            {
              icon: 'document_scanner',
              title: 'SPED Fiscal',
              topics: [
                'XXX.',
              ]
            },

            {
              icon: 'computer',
              title: 'MRWeb',
              topics: [
                'XXX.',
              ]
            },

            {
              icon: 'unarchive',
              title: 'Expedição',
              topics: [
                'XXX.',
              ]
            },
          ]}
        />

        <CTA />
      </main>
    </Layout>
  )
}
