import * as s from './styles/cta.module.scss';
import Button from './button';
import React, { ReactElement } from 'react';

export default function CTA(): ReactElement {
  return (
    <section className={s.Cta}>
      <h1 className="title u-margin-bottom">
        Comece a usar nossos sistemas
      </h1>
      <Button href="mailto:sysrs@sysrs.com.br" className="u-margin-bottom" secondary>
        Entre em contato
      </Button>
      <h2 className="subtitle">
        <small>
          Ou ligue para <a href="tel:+55 51 3524-7405" className={s.Cta_call}>+55 51 3524-7405</a>
        </small>
      </h2>
    </section>
  )
}
