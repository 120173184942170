import * as s from './styles/features.module.scss';
import Button from './button';
import React, { ReactElement, ReactNode } from 'react';

interface Props {
  primary?: boolean
  to?: string
  list: string[]
  name: string
  title?: string
  subtitle?: string
}

export default function Features({ primary, to, list, name, title, subtitle }: Props): ReactElement {
  const getTitle = (): ReactNode => {
    if (title || subtitle)
      return (
        <>
          {title && (<h1 className="title">{title}</h1>)}
          {subtitle && (<h2 className="subtitle">{subtitle}</h2>)}
        </>
      )
    return (<h3 className={s.Features_title}>Conheça seus recursos:</h3>)
  }

  return (
    <div className={`${s.Features} ${primary ? s.Features___primary : ''}`}>
      {getTitle()}
      <ul className={`list ${primary ? 'list--primary' : ''} ${s.Features_list}`}>
        {list.map((item, i) => <li key={`${name}${i}`} className="topic">{item}</li>)}
      </ul>
      {to && (
        <Button to={to} className={s.Features_button} outline secondary={!primary}>Saiba mais</Button>
      )}
    </div>
  )
}
